<template>
  <div v-if="entity !== null && hasPerm('adhesions.view_adhesion')">
    <div>
      <div class="adhesion-field-group-block field-group-block">
        <div class="field-group-header">
          <b-row>
            <b-col cols="6">
              <b>Adhésions</b>
            </b-col>
            <b-col>
              <div v-if="cancellationsCount">
                <b-form-checkbox v-model="showCancellations" id="showCancellations">
                  <counter-label :counter="cancellationsCount" label="annulation"></counter-label>
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col cols="3" class="text-right">
              <a
                class="btn btn-primary btn-sm btn-edit-child"
                href
                @click.prevent="onEdit()"
                :class="editDisabled ? 'disabled' : ''"
                v-if="!showForm && showAll && hasPerm('adhesions.add_adhesion')"
              >
                <i class="fa fa-plus"></i> Ajouter
              </a>
            </b-col>
          </b-row>
        </div>
        <div v-if="!showForm">
          <div class="field-line field-line-no-border">
            <tabs-bar
              :tabs="tabs"
              :active="activeTab"
              :enabled="!editDisabled"
              secondary
              @change="tabChanged($event)"
            ></tabs-bar>
          </div>
          <loading-gif :loading-name="loadingName"></loading-gif>
          <div v-if="!isLoading(loadingName)">
            <div v-if="adhesions && adhesions.length === 0" class="field-line">
              Aucune adhésion
            </div>
            <div
              v-for="adhesion in adhesions"
               v-bind:key="adhesion.id"
               class="field-line"
              :class="showAll ? '' : 'small-row'"
            >
              <b-row>
                <b-col :cols="showAll ? 10 : 12" :class="{ 'small-col': !showAll, }">
                  <div
                    class="main-line"
                  >
                    <b-row>
                      <b-col cols="9">
                        <span class="badge" style="vertical-align: top;" :style="adhesion.schoolYear.getHeaderStyle()">
                          {{ adhesion.schoolYear.name }}
                        </span>&nbsp;
                        <span style="vertical-align: top; display: inline-block;">
                          {{ adhesion.adhesionType.name }}
                          <span v-if="adhesion.cardNumber">N° {{ adhesion.cardNumber }}</span>
                          <span v-else>-</span>
                        </span>
                        &nbsp;
                        <span style="vertical-align: top;" v-if="adhesion.isCancelled" class="badge badge-danger">Annulée</span>
                      </b-col>
                      <b-col cols="3" class="text-right">
                        <sale-price :elt="adhesion"></sale-price>
                      </b-col>
                    </b-row>
                  </div>
                  <b-row>
                    <b-col>
                      <span v-if="adhesion.individual">{{ adhesion.individual.firstAndLastName() }}</span>
                      <span v-else>Famille</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="small grayed">
                        Le {{ adhesion.createdOn|dateToString('DD/MM/YYYY') }}
                        par {{ adhesion.createdBy|defaultValue('-') }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col :cols="showAll ? 2 : 12" :class="{ 'small-col': !showAll, }" class="text-right">
                  <invoice-badge
                    v-if="adhesion.price && adhesion.sale && (!adhesion.sale.ignored || adhesion.invoice)"
                    :invoice="adhesion.invoice"
                    :entity="entity"
                  ></invoice-badge>
                  <br />
                  <adhesion-cancel-button
                    :adhesion="adhesion"
                    :entity="entity"
                    @done="refresh()"
                    v-if="!adhesion.isCancelled"
                  >
                  </adhesion-cancel-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <div v-if="showForm && selectedAdhesionType">
          <b-form @submit.prevent="onSave()">
            <div class="field-line">
              <b-row>
                <b-col>
                  <b-form-group
                    id="adhesionTypeGroup"
                    label="Type d'adhésion"
                    label-for="adhesionType"
                  >
                    <b-form-select
                      id="adhesionType"
                      v-model="selectedAdhesionType"
                      required
                      :rel="selectedAdhesionType.id">
                      <b-form-select-option
                        :value="adhesionType"
                        v-for="adhesionType in adhesionTypes"
                        v-bind:key="adhesionType.id"
                      >
                        {{ adhesionType.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="schoolYearGroup"
                    label="Année scolaire"
                    label-for="schoolYear"
                    v-if="selectedAdhesionType.id > 0"
                  >
                    <b-form-select
                      id="schoolYear"
                      v-model="selectedPrice"
                      required
                      :disabled="adhesionTypeChoices.length <= 1"
                      :rel="selectedPrice.name">
                      <b-form-select-option
                        :value="adhesionTypeChoice"
                        v-for="adhesionTypeChoice in adhesionTypeChoices"
                        v-bind:key="adhesionTypeChoice.id"
                      >
                        {{ adhesionTypeChoice.schoolYear.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="priceGroup"
                    label="Prix"
                    label-for="price"
                    v-if="selectedPrice.id"
                  >
                    <b-form-input
                      id="price"
                      :value="selectedPrice.price|currency"
                      disabled
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    v-if="selectedAdhesionType.id > 0 && selectedPrice"
                    id="individual-group"
                    label="Bénéficiaire"
                    label-for="individual"
                  >
                    <div v-if="selectedAdhesionType.applyToIndividual()">
                      <b-form-group
                        id="individualGroup"
                        label="Membres"
                        label-for="individual"
                        v-if="entityIndividuals.length"
                      >
                        <check-box-select
                          :choices="entityIndividuals"
                          :initial-value="initIndividuals"
                          :disabled-choices="individualsWithAdhesions"
                          inline
                          id="individuals"
                          @changed="onIndividualsSelected($event.choices)"
                          @init="onIndividualsSelected($event.choices)"
                          :name-callback="individualName"
                        >
                        </check-box-select>
                      </b-form-group>
                      <div v-if="entityIndividuals.length === 0">
                        <b>Aucun membre de la famille n'est éligible à cette activité.</b>
                      </div>
                    </div>
                    <div v-else-if="selectedAdhesionType.applyToEntity()">
                      <b-form-input
                        v-if="selectedAdhesionType.applyToEntity()"
                        value="Famille"
                        disabled
                      >
                      </b-form-input>
                      <div v-if="hasFamilyAdhesion(selectedPrice)">
                        <b>La famille est déjà adhérente</b>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-row class="buttons-bar">
              <b-col class="text-left">
              </b-col>
              <b-col class="text-right">
                <a class="btn btn-secondary btn-cancel" href @click.prevent="onCancel">Annuler</a>
                <b-button type="submit" variant="primary" :disabled="hasFamilyAdhesion(selectedPrice)">
                  Ajouter
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import AdhesionCancelButton from '@/components/Adhesions/AdhesionCancelButton'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import TabsBar from '@/components/Controls/TabsBar'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import SalePrice from '@/components/Invoices/SalePrice'
import { BackendMixin } from '@/mixins/backend'
import { makeAdhesion, makeAdhesionType, makeAdhesionTypePrice } from '@/types/adhesions'
import { makeIndividual } from '@/types/people'
import { TabItem } from '@/types/tabs'
import { compareNumbers } from '@/utils/sorting'
import { BackendApi } from '@/utils/http'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'entity-adhesions',
  components: {
    CounterLabel,
    CheckBoxSelect,
    AdhesionCancelButton,
    SalePrice,
    LoadingGif,
    InvoiceBadge,
    TabsBar,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    showAll: Boolean,
  },
  data() {
    return {
      editModeEnabled: true,
      allAdhesions: [],
      adhesionTypes: [],
      selectedAdhesionType: null,
      selectedPrice: null,
      selectedIndividual: null,
      activeTab: null,
      adhesionToBeDeleted: null,
      loadingName: 'entity-adhesions',
      tabs: [
        new TabItem('actives', 'En cours', 'far fa-clock'),
        new TabItem('all', 'Historique', 'fa fa-history')
      ],
      initIndividuals: [],
      newAdhesions: [],
      showCancellations: false,
    }
  },
  computed: {
    adhesions() {
      let adhesions = this.allAdhesions
      if (!this.showCancellations) {
        adhesions = adhesions.filter(adh => !adh.isCancelled)
      }
      return adhesions
    },
    cancellationsCount() {
      return this.allAdhesions.filter(adh => adh.isCancelled).length
    },
    showForm() {
      return (store.getters.editMode === 'adhesions')
    },
    editDisabled() {
      return (store.getters.editMode !== '' && store.getters.editMode !== 'adhesions')
    },
    entityIndividuals() {
      return this.entity.getMainIndividuals()
    },
    individualsWithAdhesions() {
      return this.entityIndividuals.filter(
        individual => this.hasIndividualAdhesion(this.selectedPrice, individual)
      ).map(elt => elt.id)
    },
    adhesionTypeChoices() {
      let adhesionType = this.selectedAdhesionType
      if (adhesionType) {
        return adhesionType.prices.sort(
          (elt1, elt2) => -compareNumbers(elt1.schoolYear.startYear, elt2.schoolYear.startYear)
        )
      }
      return []
    },
  },
  created() {
    this.tabChanged(this.tabs[0])
  },
  watch: {
    selectedAdhesionType: function(newValue) {
      if (newValue) {
        this.selectedIndividual = makeIndividual()
        if (newValue.prices.length > 0) {
          this.selectedPrice = newValue.prices[0]
        } else {
          this.selectedPrice = makeAdhesionTypePrice()
        }
      }
    },
    selectedPrice: function() {
      this.selectedIndividual = makeIndividual()
    },
    entity: function() {
      this.tabChanged(this.tabs[0])
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    async onEdit() {
      if (!this.editDisabled) {
        await this.loadAdhesionTypes()
        this.selectedAdhesionType = makeAdhesionType()
        this.selectedPrice = makeAdhesionTypePrice()
        this.setEditMode('adhesions')
      }
    },
    onCancel() {
      this.setEditMode('')
      if (!this.entity.id) {
        this.getBack()
      }
    },
    async onSave() {
      const adhesionsData = []
      if (this.selectedAdhesionType.applyToIndividual()) {
        for (const elt of this.newAdhesions) {
          const dataElt = {
            adhesion_type_price: this.selectedPrice.id,
            individual: elt.individual.id,
          }
          adhesionsData.push(dataElt)
        }
      } else {
        adhesionsData.push(
          {
            adhesion_type_price: this.selectedPrice.id,
          }
        )
      }
      this.startLoading(this.loadingName)
      for (const adhesionElt of adhesionsData) {
        let backendApi = new BackendApi('post', '/api/entity/' + this.entity.id + '/adhesions/')
        try {
          let resp = await backendApi.callApi(adhesionElt)
          this.allAdhesions = [makeAdhesion(resp.data)].concat(this.adhesions)
          this.setEditMode('')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
      if (adhesionsData.length === 1) {
        await this.addSuccess('L\'adhésion a été enregistrée')
      } else if (adhesionsData.length > 1) {
        await this.addSuccess('Les adhésions ont été enregistrées')
      }
      this.$emit('changed', { adhesions: this.allAdhesions, })
      this.endLoading(this.loadingName)
    },
    async loadAdhesions(all = false) {
      if ((this.entity) && (this.entity.id > 0)) {
        this.startLoading(this.loadingName)
        let url = '/api/entity/' + this.entity.id + '/adhesions/' + (all ? '?all=1' : '')
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.allAdhesions = resp.data.map(elt => { return makeAdhesion(elt) })
          this.setEditMode('')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async loadAdhesionTypes() {
      this.adhesionTypes = []
      let backendApi = new BackendApi('get', '/api/adhesion-types/')
      try {
        let resp = await backendApi.callApi()
        this.adhesionTypes = resp.data.map(elt => makeAdhesionType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async tabChanged(tab) {
      this.activeTab = tab
      let showAll = (this.activeTab.name === 'all')
      await this.loadAdhesions(showAll)
    },
    async refresh() {
      await this.tabChanged(this.activeTab)
      this.$emit('changed', { adhesions: this.allAdhesions, })
    },
    hasIndividualAdhesion(price, individual) {
      let yearAdhesions = this.adhesions.filter(
        elt => {
          return (
            (!elt.isCancelled) &&
            (elt.schoolYear.id === price.schoolYear.id) &&
            (elt.adhesionType.applyToIndividual()) &&
            (elt.individual.id === individual.id)
          )
        }
      )
      return yearAdhesions.length > 0
    },
    hasFamilyAdhesion(price) {
      let yearAdhesions = this.adhesions.filter(adh => !adh.isCancelled).filter(
        elt => (elt.schoolYear.id === price.schoolYear.id) && elt.adhesionType.applyToEntity()
      )
      return yearAdhesions.length > 0
    },
    individualName(elt) {
      const suffix = this.hasIndividualAdhesion(this.selectedPrice, elt) ? ' (déjà adhérent)' : ''
      return elt.firstAndLastName() + suffix
    },
    onIndividualsSelected(selectedIndividuals) {
      if (this.selectedPrice === null) {
        this.newAdhesions = []
      } else {
        this.selectedIndividuals = selectedIndividuals.filter(
          individual => !this.hasIndividualAdhesion(this.selectedPrice, individual)
        )
        const currentIndividualIds = this.newAdhesions.map(elt => elt.id)
        const selectedIndividualIds = this.selectedIndividuals.map(elt => elt.id)
        // supprime les individus qui ne sont plus cochées
        for (let index = this.newAdhesions.length - 1; index >= 0; index--) {
          const ins = this.newAdhesions[index]
          if (!existsIn([ins.id], selectedIndividualIds)) {
            this.newAdhesions.splice(index, 1)
          }
        }
        // ajoute ceux qui sont nouvellement cochés
        for (const individual of selectedIndividuals) {
          if (!existsIn([individual.id], currentIndividualIds)) {
            const ins = {
              id: individual.id,
              individual: individual,
            }
            this.newAdhesions.push(ins)
          }
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .small-col {
    padding: 0;
  }
</style>
