<template>
  <span class="upload-individual-link" v-if="hasPerm('documents.add_individuallinkdocument')">
    <a href @click.prevent="showModal">
      <i class="fa fa-upload"></i> Mettre à jour
    </a>
    <b-modal
      :id="getId()"
      cancel-title="Annuler"
      :ok-disabled="isUploadDisabled()"
      @ok.prevent="onUpload"
      ok-variant="primary"
      ok-title="Ok"
    >
      <template v-slot:modal-title>
        <div>
          <i class="fa fa-upload"></i>
          Mise à jour d'une fiche liaison
        </div>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div class="the-title">
        Mettre à jour la fiche liaison {{ schoolYear.name }} de {{ member.individual.firstAndLastName() }}
      </div>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <b-form-group
                id="upload-file-group"
                label="Fichier"
                label-for="file"
                :description="'Joindre le scan de la fiche de liaison signée. Taille maximum ' + maxSizeStr"
              >
                <input type="file" id="file" ref="file" class="form-control" @change="onFileUpload()" />
              </b-form-group>
              <div class="file-detail" v-if="file">
                <b-row>
                  <b-col>Date: {{ file.lastModified | tsToDate }}</b-col>
                  <b-col class="text-right">
                    Taille: {{ file.size | fileSize }}<br />
                    <div v-if="file.size >= maxSize" class="error-text">
                      Le fichier dépasse la limite autorisée de {{ maxSize | fileSize }}
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="extraYear">
            <b-col>
              <b-form-group
                id="extraYearGroup"
                label=""
                label-for="extraYear"
                description="La fiche de liaison sera utilisée pour les deux années scolaires"
              >
                <b-form-checkbox id="extraYear" v-model="includeExtraYear">
                  Mettre aussi à jour pour {{ extraYear.name }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { fileSize } from '@/filters/texts'
import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'upload-individual-link',
  mixins: [BackendMixin],
  props: {
    member: Object,
    schoolYear: Object,
    allDocs: Array,
  },
  data() {
    return {
      randomId: getRandomId(),
      errorText: '',
      file: null,
      maxSize: 1 * 1024 * 1024,
      includeExtraYear: true,
    }
  },
  computed: {
    maxSizeStr() {
      return fileSize(this.maxSize)
    },
    extraYear() {
      if (this.schoolYear && this.allDocs) {
        const allYears = this.allDocs.map(elt => elt.schoolYear)
        const afterYears = allYears.filter(
          elt => (elt.startYear > this.schoolYear.startYear)
        ).sort(
          (year1, year2) => compareNumbers(year2, year1)
        )
        if (afterYears.length) {
          return afterYears[0]
        }
      }
      return null
    },
  },
  watch: {
    member: function() {},
    schoolYear: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-upload-individual-link' + this.randomId
    },
    isUploadDisabled() {
      return (this.file === null) || (this.file.size >= this.maxSize)
    },
    async onUpload() {
      this.errorText = ''
      if (this.file) {
        try {
          let url = '/documents/api/upload-individual-link-document/' + this.member.id + '/' + this.schoolYear.id + '/'
          let backendApi = new BackendApi('post', url)
          const lastModified = moment(new Date(this.file.lastModified)).format()
          let data = new FormData()
          data.append('document', this.file)
          data.append('save_on', lastModified)
          if (this.extraYear && this.includeExtraYear) {
            data.append('extra_year', this.extraYear.id)
          }
          const resp = await backendApi.callApi(data, 'multipart/form-data')
          this.$bvModal.hide(this.getId())
          this.$emit('uploaded')
          if (resp.data.deleted) {
            await this.addSuccess('Le fichier a été remplacé')
          } else {
            await this.addSuccess('Le fichier a été ajouté')
          }
        } catch (err) {
          this.errorText = this.getErrorText(err)
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onFileUpload() {
      this.file = this.$refs.file.files[0]
      // lastModified: 1609765680038
      // name: "millibase.json"
      // size: 312
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .the-title {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: solid 1px #ccc;
    font-weight: bold;
  }
</style>
