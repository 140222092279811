<template>
  <span class="edit-subscriptor" v-if="hasPerm('publications.add_subscriptor')">
    <b-modal dialog-class="modal-lg"
      :id="modalId"
      cancel-title="Annuler"
      :ok-disabled="isDisabled()"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Enregistrer"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-book-reader"></i> Nouvel abonné<br /></b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col cols="3">
          <b-form-group
            id="publicationType"
            description="Type"
            :disabled="publicationTypes.length <= 1"
          >
            <b-form-select
              id="publicationType"
              v-model="selectedPublicationType"
            >
              <b-form-select-option :value="itm" v-for="itm of publicationTypes" :key="itm.id">
                {{ itm.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group
            id="subscriptions-gr"
            description="Abonnements"
          >
            <b-form-select
              id="subscriptions"
              v-model="selectedSubscription"
              :disabled="subscriptions.length <= 1"
            >
              <b-form-select-option :value="elt" v-for="elt of subscriptions" :key="elt.id">
                {{ elt.shortName() }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="createdOn-group"
            description="Date"
            label-for="createdOn"
          >
            <b-form-input type="date" id="createdOn" v-model="createdOn">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="quantity-group"
            description="Quantité"
            label-for="quantity"
          >
            <b-form-input type="number" step=1 min=1 id="quantity" v-model="quantity"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="unitPrice"
            description="Prix unitaire"
            label-for="unitPrice"
            required
          >
            <decimal-input id="unitPrice" v-model="unitPrice"></decimal-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-checkbox v-model="sending" id="sending">
            Envoi
          </b-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="sendingPrice"
            description="Tarif envoi"
            label-for="sendingPrice"
          >
            <decimal-input :disabled="!sending" id="sendingPrice" v-model="sendingPrice"></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="discount"
            description="Réduction"
            label-for="discount"
            required
          >
            <decimal-input id="discount" v-model="discount"></decimal-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="totalPrice"
            description="Prix total"
            label-for="totalPrice"
            required
          >
            <decimal-input disabled id="totalPrice" v-model="totalPrice"></decimal-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <entity-typeahead
            @change="onEntityChanged"
            @init="onEntityChanged"
            :initial-id="entity ? entity.id : 0"
            :disabled="!!entity"
          >
          </entity-typeahead>
        </b-col>
      </b-row>
      <b-row v-if="totalPrice">
        <b-col>
          <b-checkbox v-model="autoInvoice" id="autoInvoice">
            Facturer automatiquement la vente
          </b-checkbox>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import EntityTypeahead from '@/components/Entity/EntityTypeahead.vue'
import { makeSubscription, makeSubscriptor } from '@/types/publications'
import { BackendApi } from '@/utils/http'
import { distinct } from '@/utils/arrays'

export default {
  name: 'edit-subscriptor-modal',
  components: { EntityTypeahead, DecimalInput, },
  mixins: [BackendMixin],
  props: {
    subscription: {
      type: Object,
      default: null,
    },
    modalId: {
      type: String,
      default: 'bv-edit-subscriptor',
    },
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      createdOn: null,
      totalPrice: 0,
      unitPrice: 0,
      discount: 0,
      sending: 0,
      sendingPrice: 0,
      errorText: '',
      quantity: 1,
      selectedEntity: null,
      autoInvoice: false,
      allSubscriptions: [],
      publicationTypes: [],
      selectedPublicationType: null,
      selectedSubscription: null,
    }
  },
  computed: {
    subscriptions() {
      if (this.subscription) {
        return [this.subscription]
      } else {
        if (this.selectedPublicationType) {
          return this.allSubscriptions.filter(elt => elt.publicationType.id === this.selectedPublicationType.id)
        } else {
          return []
        }
      }
    },
  },
  watch: {
    unitPrice: function() { this.updatePrice() },
    quantity: function() { this.updatePrice() },
    sendingPrice: function() { this.updatePrice() },
    sending: function() { this.updatePrice() },
    discount: function() { this.updatePrice() },
    selectedSubscription: function() {
      if (this.selectedSubscription) {
        this.sendingPrice = this.selectedSubscription.sendingPrice
        this.unitPrice = this.selectedSubscription.price
      } else {
        this.sendingPrice = 0
        this.unitPrice = 0
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.entity) {
        this.selectedEntity = this.entity
      }
      if (this.subscription) {
        this.publicationTypes = [this.subscription.publicationType]
        this.selectedPublicationType = this.publicationTypes[0]
        this.allSubscriptions = [this.subscription]
        this.selectedSubscription = this.subscription
      } else {
        this.loadSubscriptions()
      }
    },
    async loadSubscriptions(elt) {
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/subscriptions/'
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.allSubscriptions = resp.data.map(makeSubscription)
        this.publicationTypes = distinct(this.allSubscriptions.map(elt => elt.publicationType))
        if (this.publicationTypes.length === 1) {
          this.selectedPublicationType = this.publicationTypes[0]
        }
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
      this.endLoading(this.loadingName)
    },
    isDisabled() {
      return (
        (this.createdOn === null) ||
        (this.quantity <= 0) ||
        !this.selectedEntity ||
        !this.selectedSubscription
      )
    },
    async onSave() {
      this.errorText = ''
      let entity = this.selectedEntity
      try {
        const url = '/publications/api/add-subscriptor/'
        const backendApi = new BackendApi('post', url)
        let data = {
          subscription: this.selectedSubscription.id,
          entity: entity.id,
          unit_price: this.unitPrice,
          sending: this.sending,
          sending_price: this.sending ? this.sendingPrice : 0,
          discount: this.discount,
          total_price: this.totalPrice,
          created_on: moment(this.createdOn).format('YYYY-MM-DD'),
          auto_invoice: this.autoInvoice,
        }
        const resp = await backendApi.callApi(data)
        this.$bvModal.hide(this.modalId)
        this.$emit('done', makeSubscriptor(resp.data))
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    updatePrice() {
      let sendingPrice = 0
      if (this.sending) {
        sendingPrice = +this.sendingPrice
      }
      this.totalPrice = (+this.quantity) * (+this.unitPrice) + sendingPrice - (+this.discount)
    },
    onEntityChanged(event) {
      if (event.entity) {
        this.selectedEntity = event.entity
      } else {
        this.selectedEntity = null
      }
    },
  },
  mounted() {
    this.createdOn = moment().format('YYYY-MM-DD')
    this.init()
  },
}
</script>
<style lang="less">
</style>
